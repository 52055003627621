import React from "react";
import HeroCTA from "../HeroCTA";
import CurrentAffiliates from "../publishers/CurrentAffiliates";
import HeroMetrics from "../HeroMetrics";
import HeroTestimonial from "../HeroTestimonial";
import { Headline32 } from "../styles/Headings";
import { white } from "../styles/Colors";
import HeroBlueCTA from "../HeroBlueCTA";
import { graphql, Link, useStaticQuery } from "gatsby";
import MoreWaysToEarn from "../MoreWaysToEarn";

const PublishersAffiliateDefer = () => {
  const images = useStaticQuery(graphql`
    query {
      landingDesktop: file(relativePath: { eq: "18-affiliate-landing.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      landingMedia: file(
        relativePath: { eq: "18-affiliate-landing-mobile.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 351, maxHeight: 548, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      logoTestimonial: file(relativePath: { eq: "19-theCHIVE-logo.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      desktopTestimonial: file(
        relativePath: { eq: "19-testimonial-affiliate-desktop.png" }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      mobileTestimonial: file(
        relativePath: { eq: "19-testimonial-affiliate-mobile.png" }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);

  return (
    <>
      <HeroCTA title="OUR" outline="MARKETPLACES" />

      <CurrentAffiliates />

      <HeroMetrics
        theme={{ width: "400px" }}
        noBackground
        number="300"
        suffix="%"
        description="Publishers Earn an Average of 300% Higher Affiliate Commissions With Stack"
      />

      <HeroTestimonial media={images} author="Commerce Team @ theCHIVE">
        <Headline32 theme={{ margins: "18px 0", color: white }}>
          Over the past 4+ years, StackCommerce has provided us with unique
          digital and physical deals you can’t find on a typical affiliate
          network. Their offers clearly resonate with our audience and continue
          to drive significant revenue.
        </Headline32>
      </HeroTestimonial>

      <HeroBlueCTA
        title="Let’s create"
        outline="Together"
        description="See how Stack can help you grow your commerce and ad revenues."
        linkLabel="Get Started"
        link={{ to: "/contact/?smopt=publisher", state: { from: "publisher" }, as: Link }}
      />

      <MoreWaysToEarn />
    </>
  );
};

export default PublishersAffiliateDefer;
