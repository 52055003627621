import React, { createRef, PureComponent } from "react";
import { Headline100 } from "./styles/Headings";
import { gray1, white } from "./styles/Colors";
import { Body20 } from "./styles/BodyTypes";
import styled from "styled-components";
import { CountUp } from "countup.js";

const Div = styled.div`
  width: ${(props) => props.theme.width ?? "425px"};
  max-width: 100%;
  margin: 16px 0 0;
  text-align: ${(props) => (props.align ? "center" : "")};
  transition: ${(props) => (props.active ? "1.9s" : "0s")} ease-in color;
  color: ${(props) => (props.active ? white : gray1)};
`;

const Numbers = styled(Headline100)`
  transition: ${(props) => (props.active ? "1.9s" : "0s")} ease-in color;
  color: ${(props) => (props.active ? white : gray1)};
`;

const easingFn = function (t, b, c, d) {
  let ts = (t /= d) * t;
  let tc = ts * t;
  return b + c * (tc + -3 * ts + 3 * t);
};

class MetricSnippet extends PureComponent {
  constructor(props) {
    super(props);

    this.observerRef = createRef();

    this.state = {
      observer: null,
      play: false
    };
  }

  componentDidMount() {
    this.initPositionObserver();
  }

  componentWillUnmount() {
    if (this.state.observer) {
      this.state.observer.disconnect();
    }
  }

  initPositionObserver() {
    const self = this;
    const intersectionCallback = (entries) => {
      for (let i = 0; i < entries.length; i++) {
        if (entries[i].isIntersecting && entries[i].intersectionRatio > 0.5) {
          if (!self.state.play) {
            self.startAnimation();
            this.state.observer.disconnect();
            this.setState({ observer: null });
          }
        }
      }
    };

    // Create the observer.
    const observer = new IntersectionObserver(intersectionCallback, {
      threshold: 1
    });
    observer.observe(this.observerRef.current);
    this.setState({ observer });
  }

  startAnimation() {
    const { number, prefix, suffix, start = 1 } = this.props;

    const options = {
      easingFn,
      startVal: start,
      duration: 2,
      prefix: prefix ?? "",
      suffix: suffix ?? ""
    };

    this.setState({ play: true });

    const animation = new CountUp(this.observerRef.current, number, options);
    if (!animation.error) {
      animation.start();
    }
  }

  render() {
    const { description, number, prefix, suffix, align, theme } = this.props;

    return (
      <>
        <Numbers active={this.state.play} ref={this.observerRef} as="span">
          <span style={{ display: "none" }}>
            {prefix ?? ""}
            {number}
            {suffix ?? ""}
          </span>
        </Numbers>
        <Div active={this.state.play} align={align} theme={theme}>
          <Body20
            theme={{ color: "inherit", margins: "0" }}
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </Div>
      </>
    );
  }
}

export default MetricSnippet;
