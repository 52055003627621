import React from "react";
import styled from "styled-components";
import { Body20 } from "./styles/BodyTypes";
import Image from "./Image";
import Container from "./styles/Container";
import { white } from "./styles/Colors";
import { mediaMedium } from "./styles/variables";
import iconQuotes from "../images/11-icon-quotes.svg";
import BackgroundImage from "gatsby-background-image";

const Box = styled.div`
  max-width: 590px;
  position: relative;
  z-index: 2;
`;

const Reverse = styled(Image)`
  transform: rotate(180deg);
`;

const Wrapper = styled(BackgroundImage)`
  padding: 95px 0 89px;
  overflow: hidden;
  position: relative;
  background: #d3d0de;

  &:before,
  &:after {
    background-repeat: no-repeat !important;
    background-position: center center !important;
    background-size: cover !important;
  }

  @media (${mediaMedium}) {
    padding: 160px 0 153px;
  }
`;

const HeroTestimonial = ({ author, media, children }) => {
  const sources = media.mobileTestimonial
    ? [
        media.mobileTestimonial.childImageSharp.fluid,
        {
          ...media.desktopTestimonial.childImageSharp.fluid,
          media: `(${mediaMedium})`
        }
      ]
    : [media.desktopTestimonial.childImageSharp.fluid];

  return (
    <Wrapper fluid={sources}>
      <Container>
        <Box>
          <Image
            alt="opening quotation mark"
            src={iconQuotes}
            width={45}
            height={34}
          />
          {children}
          <Reverse
            alt="closing quotation mark"
            src={iconQuotes}
            width={45}
            height={34}
          />
          <Body20 theme={{ margins: "40px 0 22px", color: white }}>
            {author}
          </Body20>
          <Image fluid={media.logoTestimonial.childImageSharp.fluid} />
        </Box>
      </Container>
    </Wrapper>
  );
};

export default HeroTestimonial;
