import React from "react";
import styled, { css } from "styled-components";
import { Headline80 } from "./styles/Headings";
import OutlinedText from "./styles/OutlinedText";
import Container from "./styles/Container";
import { white } from "./styles/Colors";
import { mediaMedium } from "./styles/variables";
import Image from "./Image";
import arrowIcon from "../images/14-icon-down-arrow-yellow.svg";
import { WobbleFrames } from "./styles/animations";

const Wrapper = styled.div`
  background-color: ${white};
  padding: ${(props) => props.theme.paddingsMobile ?? `91px 0 88px`};

  @media (${mediaMedium}) {
    padding: ${(props) => props.theme.paddings ?? `144px 0 137px`};
  }
`;

const Box = styled(Container)`
  display: flex;
  align-items: flex-end;
`;

const Title = styled.h2`
  display: block;
  margin: 0;
`;

const Button = styled.span`
  transition: 0.2s opacity;
  width: 20px;
  margin: 0 0 0 16px;
  display: inline-block;
  animation: ${css`
      ${WobbleFrames}`} 3.5s ease infinite;

  @media (${mediaMedium}) {
    width: 25px;
    margin: 0 0 0 66px;
  }

  &:hover {
    opacity: 0.8;
  }
`;

const HeroCTA = ({ title, outline, theme }) => {
  return (
    <Wrapper theme={theme}>
      <Box>
        <Title>
          <Headline80 as="span">{title}</Headline80>
          <OutlinedText component={Headline80} as="span">
            {outline}
          </OutlinedText>
        </Title>
        <div>
          <Button>
            <Image alt="" src={arrowIcon} width={25} height={50} />
          </Button>
        </div>
      </Box>
    </Wrapper>
  );
};

export default HeroCTA;
