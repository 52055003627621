import React from "react";
import styled from "styled-components";
import Container from "./styles/Container";
import { Headline64 } from "./styles/Headings";
import { Body20 } from "./styles/BodyTypes";
import PrimaryButton from "./PrimaryButton";
import { brandColor2, white } from "./styles/Colors";
import { mediaMedium } from "./styles/variables";
import imageDecoration from "../images/12-yellow-guides.png";
import OutlinedText from "./styles/OutlinedText";

const Box = styled.div`
  max-width: 90%;

  @media (${mediaMedium}) {
    max-width: 80%;
    padding-top: 112px;
  }
`;

const Grid = styled(Container)`
  padding-top: 92px;
  padding-bottom: 96px;

  @media (${mediaMedium}) {
    padding-top: 88px;
    padding-bottom: 88px;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 16px;
    position: relative;

    &:after {
      position: absolute;
      content: "";
      width: 100%;
      top: 0;
      height: 100%;
      left: 50%;
      background: url("${imageDecoration}") top left no-repeat;
      background-size: contain;
      pointer-events: none;
    }
  }
`;

const Background = styled.div`
  background-color: ${brandColor2};
  overflow: hidden;
`;

const HeroBlueCTA = ({ title, outline, description, link, linkLabel, theme }) => {
  return (
    <Background>
      <Grid>
        <div>
          <Headline64 theme={{ color: white, ...theme }}>
            {title}
            <br />
            <OutlinedText color={white} component="span" fill={brandColor2}>
              {outline}
            </OutlinedText>
          </Headline64>
        </div>
        <Box>
          <Body20
            theme={{ color: white }}
            dangerouslySetInnerHTML={{ __html: description }}
          />
          {link && (
            <PrimaryButton attrs={link}>{linkLabel ?? "Sign Up"}</PrimaryButton>
          )}
        </Box>
      </Grid>
    </Background>
  );
};

export default HeroBlueCTA;
