import React from "react";
import styled from "styled-components";
import Container from "../styles/Container";
import Image from "../Image";
import { Headline64 } from "../styles/Headings";
import { Body20 } from "../styles/BodyTypes";
import { mediaMedium } from "../styles/variables";

// Images
import stackSocialBackground from "../../images/20-affiliate-stacksocial.png";
import stackSocialDisplay from "../../images/20-affiliate-stacksocial-mobile.png";
import stackSocialLogo from "../../images/20-affiliate-stacksocial-icon.png";
import joyusBackground from "../../images/20-affiliate-joyus.png";
import joyusDisplay from "../../images/20-affiliate-joyus-mobile.png";
import joyusLogo from "../../images/20-affiliate-joyus-icon.png";
import citizenGoodsBackground from "../../images/20-affiliate-citizen-goods.png";
import citizenGoodsDisplay from "../../images/20-affiliate-citizen-goods-mobile.png";
import citizenGoodsLogo from "../../images/20-affiliate-citizen-goods-icon.png";
import skillWiseBackground from "../../images/20-affiliate-skillwise.png";
import skillWiseDisplay from "../../images/20-affiliate-skillwise-mobile.png";
import skillWiseLogo from "../../images/20-affiliate-skillwise-icon.png";

const sections = [
  {
    title: "STACKSOCIAL",
    description:
      "Exclusive lifetime deals, curated bundles, innovative gadgets, and unique eLearning and software deals for tech enthusiasts.",
    logo: stackSocialLogo,
    logoAltText: "StackSocial logo",
    background: stackSocialBackground,
    display: stackSocialDisplay,
    displayAltText: "flying drone with a camera"
  },
  {
    title: "JOYUS",
    description:
      "Beauty, home, and women’s lifestyle finds from today’s top brands.",
    logo: joyusLogo,
    logoAltText: "Joyus logo",
    background: joyusBackground,
    display: joyusDisplay,
    displayAltText: "electric toothbrush"
  },
  {
    title: "CITIZEN GOODS",
    description: "Style, home, and everyday carry essentials for the everyman.",
    logo: citizenGoodsLogo,
    logoAltText: "Citizen Goods logo",
    background: citizenGoodsBackground,
    display: citizenGoodsDisplay,
    displayAltText: "man hiking with a backpack"
  },
  {
    title: "SKILLWISE",
    description:
      "Thousands of online courses and bundles on today’s most relevant and in-demand topics.",
    logo: skillWiseLogo,
    logoAltText: "SkillWise logo",
    background: skillWiseBackground,
    display: skillWiseDisplay,
    displayAltText: "person watching an online course"
  }
];

const Section = styled.div`
  max-width: 100%;
  margin: 41px auto;

  &:first-child {
    margin-top: 0;
  }

  @media (${mediaMedium}) {
    width: 1363px;
    height: 540px;
    background: ${(props) => `url(${props.backgroundImage})`} 50% 50% no-repeat;
    background-size: cover;
  }

  @media (max-width: 1363px) {
    background-size: cover;
  }
`;

const Display = styled.div`
  width: 100%;
  margin-bottom: 36px;

  img {
    width: 100%;
  }

  @media (${mediaMedium}) {
    display: none;
  }
`;

const Logo = styled.div`
  margin-bottom: 30px;
`;

const Grid = styled(Container)`
  display: flex;
  align-items: center;
  height: 100%;
`;

const Box = styled.div`
  width: 100%;

  @media (${mediaMedium}) {
    max-width: 500px;
  }
`;

const Text = styled.div`
  width: 100%;
  margin: 10px 0 29px;

  @media (${mediaMedium}) {
    max-width: 350px;
    margin: 20px 0;
  }
`;

const Wrapper = styled.div`
  padding-bottom: 16px;

  @media (${mediaMedium}) {
    padding-bottom: 0;
  }
`;

const CurrentAffiliates = () => {
  return (
    <Wrapper>
      {sections.map((item) => {
        return (
          <Section key={item.title} backgroundImage={item.background}>
            <Grid>
              <Box>
                <Display>
                  <Image src={item.display} alt={item.displayAltText} />
                </Display>
                <Logo>
                  <Image src={item.logo} alt={item.logoAltText} />
                </Logo>
                <Headline64>{item.title}</Headline64>
                <Text>
                  <Body20 theme={{ margins: "0" }}>{item.description}</Body20>
                </Text>
              </Box>
            </Grid>
          </Section>
        );
      })}
    </Wrapper>
  );
};

export default CurrentAffiliates;
