import React from "react";
import styled from "styled-components";
import Container from "./styles/Container";
import { black1 } from "./styles/Colors";
import { mediaMedium } from "./styles/variables";
import MetricSnippet from "./MetricSnippet";
import { graphql, useStaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";

const Wrapper = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const Background = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${black1};
  height: ${(props) => (props.noBackground ? "297px" : "297px")};
  background-size: auto 100%;
  padding: 0 40px;

  &:after,
  &:before {
    background-position: bottom center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
  }

  @media (${mediaMedium}) {
    height: ${(props) => (props.noBackground ? "480px" : "450px")};
    padding: 0;

    &:after,
    &:before {
      background-size: contain !important;
    }
  }
`;

const HeroMetricsSection = ({ images, noBackground, theme, ...props }) => {
  return (
    <Background
      as={noBackground ? "div" : BackgroundImage}
      fluid={images.file.childImageSharp.fluid}
      noBackground={noBackground}
    >
      <Wrapper>
        <MetricSnippet theme={theme} align={true} {...props} />
      </Wrapper>
    </Background>
  );
};

const HeroMetrics = (props) => {
  const images = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "10-metric-2x.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);
  return <HeroMetricsSection images={images} {...props} />;
};

export default HeroMetrics;
