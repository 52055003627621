import React from "react";
import styled from "styled-components";
import { white } from "./styles/Colors";
import { Headline40 } from "./styles/Headings";
import { Body20 } from "./styles/BodyTypes";
import { Eyebrows20 } from "./styles/Eyebrows";
import ArrowButton from "./ArrowButton";
import Container from "./styles/Container";
import { mediaMedium } from "./styles/variables";
import { Link } from "gatsby";

const Wrapper = styled.div`
  background: ${white};
  padding: 93px 0 86px;

  @media (${mediaMedium}) {
    padding: 116px 0 107px;
  }
`;

const Grid = styled.div`
  @media (${mediaMedium}) {
    padding-top: 12px;
    display: grid;
    grid-template-columns: ${(props) =>
      props.twoColumns ? "350px 350px" : "1fr 1fr 350px"};

    grid-column-gap: calc(100% * 1 / 12);
  }
`;

const Title = styled(Headline40)`
  margin: 0 0 10px;

  @media (${mediaMedium}) {
    margin: 0 0 24px;
  }
`;

const Snippet = styled.div`
  padding-top: 44px;

  &:first-child {
    padding-top: 32px;
  }

  @media (${mediaMedium}) {
    padding-top: 32px;
  }
`;

const Row = styled.span`
  display: block;
  margin: 22px 0 0;
`;

const MoreWaysToEarn = ({ noSelfShops }) => {
  return (
    <Wrapper>
      <Container>
        <Eyebrows20>More ways to earn</Eyebrows20>
        <Grid twoColumns={noSelfShops}>
          <Snippet>
            <Title>Stackmedia</Title>
            <Body20 theme={{ margins: "-9px 0 0" }}>
              CPC-based advertising solutions. Earn with every click.
              <Row>
                <ArrowButton
                  attrs={{ as: Link, to: "/publishers/stack-media/" }}
                >
                  Find Out More
                </ArrowButton>
              </Row>
            </Body20>
          </Snippet>
          <Snippet>
            <Title>Shops</Title>
            <Body20 theme={{ margins: "-9px 0 0" }}>
              Your branded Shop and access to a suite of commerce tools.
              <Row>
                <ArrowButton attrs={{ as: Link, to: "/publishers/shops/" }}>
                  Find Out More
                </ArrowButton>
              </Row>
            </Body20>
          </Snippet>
          {!noSelfShops &
          (
            <Snippet>
              <Title>Self-serve shop</Title>

              <Body20 theme={{ margins: "-9px 0 0" }}>
                Easy-to-use commerce solutions. You take the driver’s seat.
                <Row>
                  <ArrowButton
                    attrs={{
                      to: "publishers/shop/"
                    }}
                  >
                    Find Out More
                  </ArrowButton>
                </Row>
              </Body20>
            </Snippet>
          ) || ""}
        </Grid>
      </Container>
    </Wrapper>
  );
};

export default MoreWaysToEarn;
